<template>
  <div id="agenda-form-page">
    <section class="create-room">
      <div class="container">
        <h2 class="detail-title mb24">{{ title_page }}</h2>
        <form @submit.prevent="submit">
          <div class="box-create">
            <div class="row">
              <div class="col-lg-6 col-sm-6">
                <div class="create-form">
                  <label class="label-form">Title</label>
                  <input type="text" name="title" id="title_agenda" class="form-control" placeholder="Title" v-model="form.title" :class="{ 'is-invalid': $v.form.title.$error }">
                  <div v-if="$v.form.title.$error" class="invalid-feedback">
                    <span v-if="!$v.form.title.$required">This value is required.</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="create-form">
                  <label class="label-form">Case</label>
                  <select class="form-select" name="room" id="room_agenda" v-model="form.room" :class="{ 'is-invalid': $v.form.room.$error, black: form.room !== null }">
                    <option :value="null">Case</option>
                    <option v-for="(room, index) in rooms" :key="index" :value="room.id">{{ room.subject_topic }}</option>
                  </select>
                  <div v-if="$v.form.room.$error" class="invalid-feedback">
                    <span v-if="!$v.form.room.required">This value is required.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-lg-6">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="create-form" :class="[$v.form.start_date.$error ? 'date-error' : 'date']">
                      <label class="label-form" :class="{ 'is-invalid': $v.form.start_date.$error }">Start Date</label>
                      <date-picker v-model="form.start_date" format="DD-MM-YYYY" :clearable="false" placeholder="Start Date" inputClass="form-control datepicker fordate">
                        <i slot="icon-calendar"></i>
                      </date-picker>
                      <div v-if="$v.form.start_date.$error" class="invalid-feedback">
                        <span v-if="!$v.form.start_date.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="create-form" :class="[$v.form.start_date.$error ? 'date-error' : 'date']">
                      <label class="label-form" :class="{ 'is-invalid': $v.form.end_date.$error }">End Date</label>
                      <date-picker v-model="form.end_date" format="DD-MM-YYYY" :clearable="false" placeholder="End Date" inputClass="form-control datepicker fordate">
                        <i slot="icon-calendar"></i>
                      </date-picker>
                      <div v-if="$v.form.end_date.$error" class="invalid-feedback">
                        <span v-if="!$v.form.end_date.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <div class="create-form">
                  <label class="label-form" :class="{ 'is-invalid': $v.form.description.$error }">Description</label>
                  <vue-editor v-model="form.description" placeholder="Description"></vue-editor>
                  <div v-if="$v.form.description.$error" class="invalid-feedback">
                    <span v-if="!$v.form.description.required">This value is required.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="for-btn-save">
            <div class="btn-save-box">
              <button type="submit" id="save_agenda" class="btn btn-orange w-100">Save Changes</button>
            </div>
            <div class="btn-delete-box">
              <router-link to="/agenda/manage/list" id="reset_agenda" class="btn btn-delete w-100">Cancel</router-link>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import {
  required
} from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";

export default {
  name: "AgendaForm",
  components: { VueEditor },
  data() {
    return {
      rooms: [],
      form: {
        title: null,
        room: null,
        start_date: null,
        end_date: null,
        description: null,
      }
    }
  },
  validations: {
    form: {
      title: { required },
      start_date: { required },
      end_date: { required },
      room: { required },
      description: { required },
    }
  },
  computed: {
    title_page() {
      if (this.$route.name == 'AgendaEdit') {
        return 'Edit Agenda'
      } else {
        return 'Add Agenda'
      }
    }
  },
  mounted() {
    this.fetchRooms();
    if (this.$route.name == 'AgendaEdit') {
      this.getAgendaDetail() 
    }
  },
  methods: {
    getAgendaDetail() {
      this.$axios.get(`/master/agenda/${this.$route.params.id}`)
        .then(response => {
          console.log(response.data.data)
          const result = response.data.data
          this.form.title = result.title
          this.form.room = result.room_id
          this.form.start_date = new Date(this.formatDate(result.start_date))
          this.form.end_date = new Date(this.formatDate(result.end_date))
          this.form.description = result.description
        })
    },
    fetchRooms() {
      this.$axios.get('/room_list')
        .then(response => {
          console.log(response.data.data)
          this.rooms = response.data.data
        })
    },
    submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        // loading overlay
        const loader = this.$loading.show({
          container: null,
          lockScroll: true,
          opacity: 1
        });
        let submit = null
        // submit room data
        if (this.$route.name == 'AgendaEdit') {
          submit = this.$axios.patch('/master/agenda', {
            id: this.$route.params.id,
            title: this.form.title,
            room_id: this.form.room,
            start_date: this.formatDate(this.form.start_date),
            end_date: this.formatDate(this.form.end_date),
            description: this.form.description
          })
        } else {
          submit = this.$axios.post('/master/agenda', {
            title: this.form.title,
            room_id: this.form.room,
            start_date: this.formatDate(this.form.start_date),
            end_date: this.formatDate(this.form.end_date),
            description: this.form.description
          })
        }

        submit.then(response => {
            console.log(response);
            loader.hide();
            Swal.fire("Success", response.data.message, "success");
            this.$router.push('/agenda/manage/list')
          }).catch(error => {
            loader.hide();
            console.log(error);
          })
      }
    }
  }
}
</script>

<style lang="css" scoped>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.mx-datepicker {
  width: 100% !important;
}
.mx-icon-calendar {
  display: none !important;
}
.create-form.date-error::after{
  font-family: 'icomoon';
  content: "\e906";
  font-size: 20px;
  position: absolute;
  right: 15px;
  bottom: 40px;
  color: #434343;
}
.black {
  color: black !important;
}
</style>